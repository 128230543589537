<template>
  <div id="film-block" v-if="show">
    <div v-if="List.length > 0">
      <swiper :options="realOption">
        <swiper-slide
          v-for="(item, index) in List"
          :key="item.Id"
          :id="index"
          class="swiper-video-item"
        >
          <div class="swiper-video-inner">
            <div
              class="video-inner-front"
              :style="'background-image:url(' + item.ImgUrl + ')'"
              v-show="item.isFront"
            >
              <div class="video-inner-front-tag" v-if="item.AngleMark">
                <div class="video-inner-front-tag-title">
                  {{ item.AngleMark }}
                </div>
              </div>
            </div>
            <div class="video-inner-back" v-show="!item.isFront">
              <div class="swiper-video-inner-text">
                <p>{{ item.Name }}</p>
                <p>国家：{{ item.Country }}</p>
                <p>导演：{{ item.Director }}</p>
                <p>时长：{{ item.DurationMinutes }}分钟</p>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FilmBlock",
    data() {
      let vm = this;
      return {
        swiperOption: {
          effect: "slide",
          loop: false,
          slidesPerView: "auto",
          on: {
            tap() {
              const index = this.clickedSlide.id;
              const typeIndex = vm.typeIndex;
              const isFront = vm.List[index].isFront;
              if (isFront) {
                vm.ontap(index);
              } else {
                vm.onlinkTap(typeIndex, index);
              }
            },
          },
        },
      };
    },
    props: {
      List: {
        type: Array,
        default: () => [],
        required: true,
      },
      typeIndex: {
        type: Number,
        required: true,
      },
      show: {
        type: Boolean,
        default: true,
      },
    },

    mounted() {},
    computed: {
      isShow() {
        return false;
      },
      realOption() {
        let newOption = this.swiperOption;
        return newOption;
      },
    },
    methods: {
      ontap(index) {
        let front = this.List[index].isFront;
        this.List[index].isFront = !front;
      },
      onlinkTap(typeIndex, index) {
        this.$emit("onlinkTap", typeIndex, index);
      },
    },
  };
</script>

<style scoped>
  .swiper-video-item {
    width: 42%;
    /* width: 66%; */
    margin-left: 4%;
  }
  .swiper-video-item:last-child {
    margin-right: 4%;
  }
  .swiper-video-inner {
    width: 100%;
    height: 0;
    padding-bottom: 149.83%;
  }
  .video-inner-front {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 149.83%;
    border-radius: 1px;
  }
  .video-inner-front-tag {
    display: inline-block;
    min-width: 32%;
    min-height: 2vh;
    padding: 6px 8px;
    font-size: 0;
    background-color: #1fa5e4;
    border-radius: 0 0 3px 0;
  }
  .video-inner-front-tag-title {
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  .video-inner-back {
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    /* padding: 0 30px; */
    padding-bottom: 149.83%;
    background-color: #1fa5e4;
    border-radius: 1px;
    outline: 1px solid #fff;
    outline-offset: -10px;
  }

  .swiper-video-inner-text {
    position: absolute;
    margin: 5%;
    padding: 10px;
    box-sizing: border-box;
    color: #fff;
    font-weight: 500;
  }
  .swiper-video-inner-text p {
    line-height: 28px;
  }
  .swiper-video-inner-text p:first-child {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    padding-bottom: 10px;
  }
  /* .swiper-video-inner-text {
    position: absolute;
    top: 50%;
    line-height: 28px;
    margin-top: -70px;
  } */
</style>
