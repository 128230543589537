<template>
  <div id="home">
    <div class="main_bg"></div>
    <!-- 菜单 -->
    <Header :backgroundColor="menuColor"></Header>
    <!-- banner轮播图 -->
    <div id="banner">
      <el-carousel
        indicator-position="none"
        :autoplay="true"
        arrow="always"
        :height="(screenWidth / 16) * 9 + 'px'"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="item.id">
          <img :src="item.imgurl" width="100%" @click="bannerLinkTap(index)" />
        </el-carousel-item>
      </el-carousel>
      <div class="botBg"></div>
    </div>

    <!-- 报名通道 -->
    <div id="sign-up-container">
      <div class="sign-up-item" v-if="bmbt.status == 0">
        <img src="@/assets/images/index/vt0.png" />
      </div>
      <div class="sign-up-item" @click="zpLink" v-if="bmbt.status == 1">
        <img src="@/assets/images/index/vt1.png" />
      </div>
      <div class="sign-up-item" v-if="bmbt.status == 2">
        <img src="@/assets/images/index/vt2.png" />
      </div>
    </div>

    <div id="info-container">
      <!-- <div class="topBg"></div> -->
      <!-- 新闻 -->
      <div class="info-area news-area">
        <div class="info-titles">
          <div
            v-for="(item, index) in newsList"
            :key="item.title"
            @click="newsFocusId = index"
            :class="getNewsTitleClass(index)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="info-main">
          <div class="info-main-item">
            <div class="item-slideshow">
              <slideshow
                v-for="(item, index) in newsList"
                :key="item.title"
                :List="item.list"
                :typeIndex="index"
                @ontap="newsLinkTap"
                :isShow="newsFocusId == index"
              ></slideshow>
            </div>
          </div>
        </div>
      </div>

      <!-- 展映 -->
      <div class="info-area film-area">
        <div class="info-titles">
          <!-- <div
            v-for="(item, index) in filmList"
            :key="item.type"
            @click="filmFocusId = index"
            class="info-titles-item"
          >
            <img
              :src="filmFocusId == index ? item.imgfocus : item.img"
              style="width: 50%"
            />
          </div> -->
          <div
            v-for="(item, index) in filmList"
            :key="item.title"
            @click="filmFocusId = index"
            :class="getFilmTitleClass(index)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="info-main">
          <div class="info-main-item">
            <div class="item-slideshow" id="video-slideshow">
              <FilmBlock
                v-for="(item, index) in filmList"
                :key="item.type"
                :List="item.list"
                :typeIndex="index"
                :show="filmFocusId == index"
                @onlinkTap="filmLinkTap"
              />
            </div>
          </div>
          <div class="film-more">
            <div @click="linkToFilm">了解更多</div>
          </div>
        </div>
      </div>

      <!-- 创投 -->
      <div class="info-area back-area">
        <div class="index-news-titles">
          <div class="index-news-titles-item">创投</div>
        </div>
        <div class="info-main">
          <div class="info-main-item">
            <div class="item-activeblock">
              <active-block
                :List="vcList"
                @ontap="vcLinkTap"
                titleBlock
              ></active-block>
            </div>
          </div>
        </div>
      </div>

      <!-- 活动 -->
      <div class="info-area img-back-area">
        <div class="index-news-titles">
          <div class="index-news-titles-item">活动</div>
          <div class="index-news-subtitle">
            <div
              v-for="(item, index) in acList"
              :key="item.title"
              @click="acFocusId = index"
            >
              <div
                v-if="acFocusId == index"
                class="index-news-subtitle-item-focus"
              >
                {{ item.title }}
                <div class="index-news-subtitle-item-focus-bottom"></div>
              </div>
              <div v-else class="index-news-subtitle-item">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="info-main">
          <div class="info-main-item">
            <div class="item-activeblock">
              <active-block
                v-for="(item, index) in acList"
                :key="item.title"
                :List="item.list"
                :typeIndex="index"
                @ontap="acLinkTap"
                :show="acFocusId == index"
              ></active-block>
            </div>
          </div>
        </div>
      </div>

      <!-- 电影·浙江日 -->
      <!-- <div class="info-area back-area">
        <div class="index-news-titles">
          <div class="index-news-titles-item">电影·浙江日</div>
        </div>
        <div class="info-main">
          <div class="info-main-item">
            <div class="item-activeblock">
              <div class="block-item" v-if="zjList.length > 0">
                <div
                  class="block-item-inner"
                  :style="'background-image:url(' + zjList[0].imgurl + ')'"
                ></div>
                <p>{{ zjList[0].outTitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- footer -->
      <Footer />
    </div>
  </div>
</template>

<script>
  import wx from "weixin-js-sdk";
  import {
    getList,
    getSignature,
    getFilmList,
    getIP,
    // getCloseTime,
  } from "@/network/index";
  import Header from "./components/Header.vue";
  import Footer from "./components/Footer.vue";
  import ActiveBlock from "./components/ActiveBlock.vue";
  import FilmBlock from "./components/FilmBlock.vue";
  import Slideshow from "./components/Slideshow.vue";
  export default {
    name: "Home",
    components: { Header, Footer, ActiveBlock, Slideshow, FilmBlock },
    data() {
      return {
        screenWidth: document.documentElement.clientWidth,
        menuColor: "rgba(0, 0, 0, 0.5)",

        bannerList: [],
        // 报名
        bmbt: {
          title: "志愿者招募",
          startdate: "2024-09-20",
          enddate: "2024-09-30",
          status: 1,
        },
        // 新闻
        newsFocusId: 0,
        newsList: [
          {
            title: "新闻动态",
            type: "新闻动态",
            list: [],
          },
          { title: "往届回顾", type: "往届回顾", list: [] },
        ],
        //展映
        filmFocusId: 0,
        filmList: [
          // {
          //   title: "中外影片展映",
          //   type: "wj",
          //   list: [],
          // },
          {
            title: "往届电影展映",
            type: "wj",
            list: [],
          },
          {
            title: "中外影片展映",
            type: "bj",
            list: [],
          },
        ],
        // 创投
        vcList: [],
        // 活动
        acFocusId: 0,
        acList: [
          {
            title: "十大浙产影片评选",
            type: "活动-十大浙产影片评选",
            list: [],
          },
          { title: "大学生电影周", type: "活动", list: [] },
          { title: "电影好故事", type: "活动-电影好故事", list: [] },
        ],
        // 电影·浙江日
        zjList: [],
      };
    },
    created() {
      if (location.href.includes("shareUrl")) {
        console.log("分享页面", location.href);
        location.href =
          "https://www.dyz.zjfilm.cn/#" +
          location.href.split("shareUrl=")[1].split("#")[0];
        return;
      }

      this.init();
    },
    mounted() {
      this.wxShare();
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth;
        })();
      };
      if (window.performance.navigation.type == 0) {
        this.getIPData();
      }
    },
    methods: {
      // 初始化
      init() {
        this.getListData();
        this.getFilmList();
        this.getCloseMsg();
      },
      // 微信分享
      wxShare() {
        const url = encodeURIComponent(location.href.split("#")[0]);
        const data = { url };
        getSignature(data)
          .then((res) => {
            console.log("wxShare", res);
            wx.config({
              debug: false,
              appId: res.appId,
              timestamp: res.timestamp,
              nonceStr: res.nonceStr,
              signature: res.signature,
              jsApiList: [
                "onMenuShareTimeline",
                "onMenuShareAppMessage",
                "onMenuShareQQ",
                "onMenuShareWeibo",
                "onMenuShareQZone",
              ],
            });
            //分享配置

            wx.ready(function () {
              const shareData = {
                title: "2024青年电影周（浙江）",
                desc: "让青年人成就电影梦想",
                link: "https://www.dyz.zjfilm.cn/",
                imgUrl:
                  "https://dyz.zjfilm.cn/dist/test/static/img/3.f383314d.png",
              };
              wx.onMenuShareTimeline(shareData);
              wx.onMenuShareAppMessage(shareData);
              wx.onMenuShareQQ(shareData);
              wx.onMenuShareWeibo(shareData);
              wx.onMenuShareQZone(shareData);
            });
          })
          .catch(function (err) {
            console.log(err);
          });
      },
      // 网络请求
      // 获取访问IP
      getIPData() {
        const data = {};
        getIP(data).then((res) => {
          console.log("IP访问", res);
        });
      },
      // 获取报名结束时间
      getCloseMsg() {
        const now = new Date();
        const start = new Date("2024-09-19");
        const end = new Date("2024-09-30");
        if (now > end) this.bmbt.status = "2";
        if (now <= start) this.bmbt.status = "0";
        // getCloseTime().then((res) => {
        //   if (res.msg === "报名已截止") {
        //     this.bmbt.status = 0;
        //   }
        // });
      },
      // 获取列表数据
      getListData() {
        let data = {};
        getList(data).then((res) => {
          let result = res.result;
          // 图片处理
          result.forEach((item) => {
            if (item.imgurl.length > 0) {
              item.imgurl = item.imgurl[0].url;
            } else {
              item.imgurl = "";
            }
          });
          console.log("list请求结果", result);

          // 轮播图
          this.bannerList = result.filter((item) => {
            return item.category == "首页轮播";
          });
          // 新闻动态newsList
          this.newsList.forEach((item) => {
            item.list = result.filter((itemC) => {
              return itemC.category == item.type;
            });
          });
          // 创投
          this.vcList = result.filter((item) => {
            return item.category == "创投";
          });
          // 活动acList
          this.acList.forEach((item) => {
            item.list = result.filter((itemC) => {
              return itemC.category == item.type;
            });
          });
          // 中国电影 • 浙江日
          this.zjList = result.filter((item) => {
            return item.category == "中国电影 • 浙江日";
          });

          // console.log("轮播图", this.bannerList);
          // console.log("新闻相关", this.newsList);
          // console.log("创投", this.vcList);
          // console.log("活动相关", this.acList);
          // console.log("中国电影 • 浙江日", this.zjList);
        });
      },
      getFilmList() {
        let data = {};
        getFilmList(data).then((res) => {
          let result = res.result;
          // 数据处理
          result.forEach((item) => {
            item.isFront = true;
            if (item.ImgUrl.length > 0) {
              item.ImgUrl = item.ImgUrl[0].url;
            } else {
              item.ImgUrl = "";
            }
          });
          console.log("电影请求结果", result);

          this.filmList.forEach((item) => {
            item.list = result.filter((itemC) => {
              return itemC.Category == item.type;
            });
          });
          console.log("展映列表", this.filmList);
        });
      },
      // 跳转
      // 地址处理
      linkAddress(item) {
        if (item.ifout == true) {
          const url = item.url;
          if (url == null || url == "") {
            console.log("未填写链接");
            return;
          }
          const path = url.split("dyz.zjfilm.cn/#")[1];
          if (!path) {
            console.log(url);
            window.location.href = url;
            return;
          }
          let newUrl = window.location.href.split("#")[0] + "#" + path;
          if (newUrl.indexOf("?") != -1) {
            newUrl = newUrl + "&type=" + item.category + "&auId=" + item.id;
          }
          console.log(newUrl);
          window.location.href = newUrl;

          // const linkUrl =
          //   item.h5url + "&type=" + item.category + "&auId=" + item.id;
          // console.log(linkUrl);
          // item.h5url == null || item.h5url == ""
          //   ? console.log("未填写链接")
          //   : (window.location.href = linkUrl);
        } else {
          this.$router.push({
            path: "/news",
            query: {
              id: item.id,
              type: item.category,
              auId: item.id,
            },
          });
        }
      },
      // 报名通道跳转
      zpLink() {
        this.$router.push({
          path: "/volunteer",
        });
      },

      // 轮播跳转
      bannerLinkTap(index) {
        const item = this.bannerList[index];
        this.linkAddress(item);
      },
      // 新闻跳转
      newsLinkTap(typeIndex, index) {
        const list = this.newsList[typeIndex].list;
        const item = list[index];
        this.linkAddress(item);
      },
      // 展映跳转
      filmLinkTap(typeIndex, index) {
        const list = this.filmList[typeIndex].list;
        const item = list[index];
        this.$router.push({
          path: "/zy/mvdetail",
          query: {
            id: item.Id,
          },
        });
      },
      // 电影二级页跳转
      linkToFilm() {
        this.$router.push({
          path: "/zy/wangjie",
        });
      },
      // 创投跳转
      vcLinkTap(index) {
        const item = this.vcList[index];
        this.linkAddress(item);
      },
      // 活动跳转
      acLinkTap(typeIndex, index) {
        const list = this.acList[typeIndex].list;
        const item = list[index];
        this.linkAddress(item);
      },

      //
      getNewsTitleClass(id) {
        return this.newsFocusId == id
          ? "info-titles-item info-titles-item-focus"
          : "info-titles-item";
      },
      getFilmTitleClass(id) {
        return this.filmFocusId == id
          ? "info-titles-item info-titles-item-focus"
          : "info-titles-item";
      },
    },

    computed: {},
  };
</script>

<style scoped>
  .main_bg {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: url("../assets/images/index/bg1.png");
    z-index: -10;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center;
  }

  /* 轮播图 */
  #banner {
    position: relative;
    width: 100%;
    background-image: url("../assets/images/index/bg1.png");
    background-position: top;
    background-repeat: repeat;
  }
  .botBg {
    position: absolute;
    left: 0;
    height: 50px;
    width: 100%;
    z-index: 100;
    bottom: 0;
    background: -webkit-gradient(
      linear,
      0 0,
      0 bottom,
      from(rgba(0, 137, 236, 0)),
      to(rgb(1, 38, 80))
    );
  }

  /* 报名通道 */
  #sign-up-container {
    position: relative;
    width: 100%;
    background-image: url(../assets/images/index/2.png);
    background-size: 100% auto;
    background-position-y: -50px;
    font-size: 0;
    text-align: center;
    padding: 20px 0;
  }
  .sign-up-txt-item {
    position: relative;
    /* display: inline-block; */
    vertical-align: middle;
    /* margin-right: 25px; */
    background-image: url(../assets/images/index/index12.png);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* width: 30%; */
    width: 24%;
    margin: 0 38%;
    top: -15px;
    height: 0;
    padding-bottom: 12%;
    /* padding-bottom: 15.69%; */
  }
  .sign-up-item {
    position: relative;
    width: 40%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 2.5%;
    color: #fff;
  }
  .bmtd-item {
    background: url("../assets/images/index/btbg.png") no-repeat;
    background-size: 100% 100%;
  }
  .bmtd-title {
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
  .bmtd-subtitle {
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }
  .bmtd-div {
    height: 130px;
    display: inline-block;
    vertical-align: top;
  }
  .inl-block {
    display: inline-block;
    vertical-align: top;
  }
  .sign-up-item img {
    width: 100%;
    display: block;
  }

  /* 动态、展映 */
  #info-container {
    position: relative;
    width: 100%;
    /* background-image: url("../assets/images/index/bg1.png"); */
    background-position: top;
    /* background-repeat: no-repeat;
    background-size: 100% 100%; */
  }
  .topBg {
    z-index: 1;
    top: 0;
    background: -webkit-gradient(
      linear,
      0 0,
      0 bottom,
      from(rgb(1, 38, 80)),
      to(rgba(0, 137, 236, 0))
    );
    position: absolute;
    left: 0;
    height: 20px;
    width: 100%;
  }

  .news-area {
    background-image: url("../assets/images/index/1.png");
  }
  .film-area {
    background-image: url(../assets/images/index/2.png);
    background-size: 100% auto;
    background-position-y: -20px;
  }

  .info-area {
    position: relative;
    width: 100%;
    padding-bottom: 1px;
  }
  .info-titles {
    position: relative;
    width: 100%;
    padding: 20px 0 0 0;
    display: flex;
  }
  .info-titles-item {
    position: relative;
    flex: 1;
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    letter-spacing: 1px;
    color: #000;
    text-align: center;
    font-family: "Microsoft YaHei", 微软雅黑;
  }
  .info-titles-item-focus {
    color: #0288f0;
    font-weight: 700;
  }
  .info-main {
    position: relative;
    width: 100%;
  }
  .info-main-item {
    position: relative;
    width: 100%;
  }
  .item-slideshow {
    position: relative;
    width: 100%;
    margin: 20px auto;
  }
  .film-more {
    color: #000;
    text-align: center;
    font-family: "Microsoft YaHei", 微软雅黑;
    font-size: 16px;
    padding-bottom: 20px;
  }
  .film-more div {
    display: inline-block;
    padding: 0 10px;
  }
  .split-line {
    border-top: 1px solid #000;
  }

  /* 活动 */

  .index-news-titles {
    position: relative;
    width: 100%;
    font-size: 0;
    text-align: center;
    padding: 20px 0 20px 0;
  }
  .index-news-titles-item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    letter-spacing: 2px;
    color: #000;
    font-family: "Microsoft YaHei", 微软雅黑;
    box-sizing: border-box;
    width: auto;
  }
  .index-news-subtitle {
    position: relative;
    width: 90%;
    margin-left: 5%;
    /* width: 75%;
    margin-left: 12.5%; */
    font-size: 0;
    text-align: center;
    padding-top: 10px;
    display: flex;
  }
  .index-news-subtitle div {
    flex: auto;
  }
  .index-news-subtitle-item {
    position: relative;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    color: #000;
    font-family: "Microsoft YaHei", 微软雅黑;
    box-sizing: border-box;
    width: auto;
  }
  .index-news-subtitle-item-focus {
    position: relative;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    color: #0288f0;
    font-family: "Microsoft YaHei", 微软雅黑;
    box-sizing: border-box;
    width: auto;
  }
  .index-news-subtitle-item-focus-bottom {
    width: 20px;
    border-bottom: 2px solid #0288f0;
    margin: 5px auto 0 auto;
  }
  .back-area {
    background-image: url("../assets/images/index/3.png");
  }
  .img-back-area {
    position: relative;
    width: 100%;
    background-image: url("../assets/images/index/4.png");
    background-repeat: no-repeat;
    background-size: 110% 110%;
  }
  .item-activeblock {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
  }

  /* 电影·浙江日 */
  .block-item {
    position: relative;
    padding: 0 20px;
  }
  .block-item-inner {
    width: 100%;
    padding-bottom: 56.25%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .block-item p {
    width: 100%;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: -30px;
    padding: 0 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
</style>
