<template>
  <div id="active-block" v-if="show && List.length > 0">
    <div v-if="!titleBlock">
      <swiper :options="realOption">
        <swiper-slide
          v-for="(item, index) in List"
          :key="item.id"
          :id="index"
          class="swiper-news-item"
        >
          <div
            class="swiper-news-inner"
            :style="'background-image:url(' + item.imgurl + ')'"
          ></div>
          <p>{{ item.outTitle }}</p>
        </swiper-slide>
      </swiper>
      <div :id="'pagination' + typeIndex" class="pagination"></div>
    </div>
    <!-- titleBlock -->
    <div v-else>
      <swiper :options="realOption">
        <swiper-slide
          v-for="(item, index) in List"
          :key="item.id"
          :id="index"
          class="swiper-news-item"
        >
          <div
            class="swiper-news-inner"
            :style="'background-image:url(' + item.imgurl + ')'"
          ></div>
        </swiper-slide>
      </swiper>
      <div class="title-background">
        <div class="p2-title">{{ List[blockId].outTitle }}</div>
        <div class="title-backimg"></div>
      </div>
      <div
        :id="'pagination' + typeIndex"
        class="pagination"
        style="margin: 11% 0 20px 0"
      ></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ActiveBlock",
    data() {
      let vm = this;
      return {
        blockId: 0,
        swiperOption: {
          effect: "coverflow",
          loop: true,
          autoplay: false,
          centeredSlides: true,
          slidesPerView: "auto",
          coverflowEffect: {
            rotate: 0,
            stretch: -20,
            depth: 80,
            modifier: 3,
            slideShadows: false,
          },
          pagination: {
            el: "#" + "pagination" + vm.typeIndex,
            clickable: true,
            bulletClass: "pagination-item",
            bulletActiveClass: ".pagination-item focus",
          },
          on: {
            transitionEnd() {
              if (vm.titleBlock) {
                vm.blockId = this.realIndex;
              }
            },
            tap() {
              const index = this.clickedSlide.id;
              const typeIndex = vm.typeIndex;
              vm.ontap(typeIndex, index);
            },
          },
        },
      };
    },
    props: {
      List: {
        type: Array,
        default: () => [],
        required: true,
      },
      typeIndex: {
        type: Number,
        default: -1,
      },

      titleBlock: {
        type: Boolean,
        default: false,
      },
      show: {
        type: Boolean,
        default: true,
      },
    },
    mounted() {},
    computed: {
      isLoop() {
        return this.List.length > 1;
      },
      realOption() {
        let newOption = this.swiperOption;
        newOption.loop = this.isLoop;
        newOption.allowTouchMove = this.isLoop;
        return newOption;
      },
    },
    methods: {
      ontap(typeIndex, index) {
        if (this.titleBlock) {
          this.$emit("ontap", index);
        } else {
          this.$emit("ontap", typeIndex, index);
        }
      },
    },
  };
</script>

<style scoped>
  .swiper-container {
    z-index: 0;
  }
  .swiper-news-item {
    position: relative;
    width: 75%;
    box-sizing: border-box;
  }
  .swiper-news-inner {
    width: 100%;
    padding-bottom: 62%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .swiper-news-item p {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: 1px;
    bottom: 10px;
    padding: 0 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .title-background {
    position: absolute;
    width: 68%;
    margin-top: -20px;
    margin-left: 16%;
    height: 27%;
    background: #1fa5e4;
  }
  .p2-title {
    position: relative;
    z-index: 1;
    font-size: 14px;
    color: #fff;
    font-family: 微软雅黑;
    line-height: 26px;
    padding: 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .title-backimg {
    width: 160px;
    height: 51.5px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    /* background-image: url("../../assets/images/font-back.png"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .pagination {
    position: relative;
    text-align: center;
    bottom: -10px;
  }
</style>
<style>
  .pagination-item {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    background-color: #c0c4cc;
    opacity: 0.5;
    height: 4px;
    width: 6px;
    margin: 0 3px;
  }
  .pagination-item.focus {
    opacity: 1;
    width: 15px !important;
    background: #fec93b !important;
  }
</style>
