<template>
  <div id="slideshow" v-if="isShow">
    <swiper :options="realOption" v-if="List.length > 0">
      <swiper-slide
        v-for="(item, index) in List"
        :key="item.id"
        :id="index"
        class="swiper-news-item"
      >
        <div
          class="swiper-news-inner"
          :style="'background-image:url(' + item.imgurl + ')'"
        ></div>
        <p>{{ item.outTitle }}</p>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  export default {
    name: "SlideShow",
    data() {
      let vm = this;
      return {
        swiperOption: {
          effect: "coverflow",
          loop: true,
          autoplay: true,
          centeredSlides: true,
          slidesPerView: "auto",
          coverflowEffect: {
            rotate: 0,
            stretch: 10,
            depth: 150,
            modifier: 2,
            slideShadows: false,
          },
          on: {
            tap() {
              const index = this.clickedSlide.id;
              const typeIndex = vm.typeIndex;
              vm.ontap(typeIndex, index);
            },
          },
        },
      };
    },
    props: {
      List: {
        type: Array,
        default: () => [],
        required: true,
      },
      isShow: {
        type: Boolean,
        default: true,
      },
      typeIndex: {
        type: Number,
        required: true,
      },
    },
    mounted() {},
    computed: {
      realOption() {
        let newOption = this.swiperOption;
        return newOption;
      },
    },
    methods: {
      ontap(typeIndex, index) {
        this.$emit("ontap", typeIndex, index);
      },
    },
  };
</script>

<style scoped>
  .swiper-news-item {
    width: 66%;
    box-sizing: border-box;
    height: 0;
    padding-bottom: 57.75%;
    background-color: #fff;
    border: 1px solid rgba(33, 166, 228, 0.8);
  }
  .swiper-news-inner {
    width: 100%;
    padding-bottom: 62%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .swiper-news-item p {
    width: 100%;
    color: #000;
    box-sizing: border-box;
    line-height: 24px;
    font-size: 14px;
    margin: 8px 0;
    padding: 0 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
</style>
